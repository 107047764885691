import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProjectsNavigation from "../../components/projects-navigation"
import ProjectMW from "../../components/project-mw"
import { content } from "../pages.module.scss"

export default function PageProjectMW() {
  const currentLang = "en"
  return (
    <Layout lang={currentLang}>
      <Seo
        lang={currentLang}
        title="Project - Martyna Wojciechowska"
        keywords={[`powszuk`, `design`]}
      />
      <div className={content}>
        <ProjectMW lang={currentLang} />
      </div>
      <ProjectsNavigation lang={currentLang} project="martyna-wojciechowska" />
    </Layout>
  )
}
